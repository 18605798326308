export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "adventures in programming",

  author: "Matt Thompson",
  description: "A random collection of blog posts about random coding adventures and learnings, by Matt Thompson. Software Engineer currently employed at ADK Group in Boston MA. Background in 3D Animation briefly before found programming. Side project warrior and weekend Game Developer.",

  // The number of posts to a page on the site index.
  indexPageSize: 4,
}
