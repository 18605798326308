import React from 'react'
import styles from './Bio.module.css'
import { getGravatarURL } from '../utils/getGravatarURL'

function Bio(props) {
  let photoURL = getGravatarURL({
    email: "test1@example.com",
    size: 56,
  })

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={'https://i.imgur.com/zleuP19.jpg?1'} alt="Me" />
      <p>
        Hi I am {' '}
        <a href="https://www.linkedin.com/in/codemmt/">Matt Thompson</a>. I post here about code going ons in my life, sometimes!
        I am a Software Engineer currently employed at ADK Group in Boston MA. Background in 3D Animation briefly before found programming.
        Side project warrior and weekend Game Developer.
      </p>
    </div>
  )
}

export default Bio